import { useEffect, useMemo } from "react";
import { IMAGES } from "../assets/images";
import { useReviewsQuery } from "../hooks/query/useReviews.query";

const Testimonials = () => {
  const { data, isLoading } = useReviewsQuery();

  const reviews: TReviews[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    new Swiper(".testimonials-swiper", {
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      spaceBetween: 0,

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      loop: true,
    });
  }, []);

  return (
    <section className="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="testimonials__main">
              <div className="block-text center">
                <h6 className="sub-heading">
                  <span>Reviews</span>
                </h6>
                <h3 className="heading wow" data-splitting>
                  What People Say
                </h3>
              </div>

              <div className="swiper testimonials-swiper pb-5">
                <div className="swiper-wrapper">
                  {reviews.length > 0 &&
                    reviews?.map((review) => (
                      <div className="swiper-slide" key={review._id}>
                        <div className="box-testimonial center">
                          <img
                            src={IMAGES.quote}
                            alt=""
                            className="quoteImage"
                          />
                          <p className="text">"{review.title}"</p>
                          <p className="text-message mt-24">{review.review}</p>
                          <div className="info">
                            <div className="user_avatar">
                              <p>{review.name[0]}</p>
                            </div>
                            <h5 className="name">{review.name}</h5>
                            <p>
                              {review.verifiedBlueTick
                                ? "Verified User"
                                : "Ordinary User"}
                            </p>
                            <div className="d-flex justify-content-center">
                              {Array.from(
                                { length: review.rating },
                                (_, index) => (
                                  <img
                                    key={index}
                                    src={IMAGES.starRating}
                                    className="image-fluid rating_star"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import { IMAGES } from "../assets/images";
import { useSettingsQuery } from "../hooks/query/useSettings.query";
import { useMemo } from "react";

const TheHeader = () => {
  const { data, isLoading } = useSettingsQuery();

  const settings: TSettings = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  return (
    <header id="header_main" className="header">
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <a href="/">
                  <img
                    id="site-logo"
                    src={IMAGES.logo}
                    alt="Peson"
                    width="160"
                    height="38"
                    data-retina={IMAGES.logo2x}
                    data-width="160"
                    data-height="38"
                  />
                </a>
              </div>

              <div className="header__right">
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    <li
                      className="menu-item  menu-current-item"
                      style={{ paddingRight: "30px" }}
                    >
                      <a href="/" style={{ fontSize: "13px" }}>
                        Home
                      </a>
                    </li>

                    <li className="menu-item" style={{ paddingRight: "30px" }}>
                      <a href="/whatIsBenylo" style={{ fontSize: "13px" }}>
                        Benylo?
                      </a>
                    </li>

                    <li className="menu-item" style={{ paddingRight: "30px" }}>
                      <a href="/WhitePaper" style={{ fontSize: "13px" }}>
                        Whitepaper
                      </a>
                    </li>
                    <li className="menu-item" style={{ paddingRight: "30px" }}>
                      <a href="/tokenomics" style={{ fontSize: "13px" }}>
                        Tokenomics
                      </a>
                    </li>
                    <li className="menu-item" style={{ paddingRight: "30px" }}>
                      <a href="/privacypolicy" style={{ fontSize: "13px" }}>
                        Privacy Policy
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="/termsandconditions"
                        style={{ fontSize: "13px" }}
                      >
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="mobile-button">
                  <span></span>
                </div>
              </div>

              <div className="header__action ">
                <a
                  download={true}
                  href={settings?.applicationUrl}
                  className="action-btn m-0"
                >
                  <span>Download</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TheHeader;

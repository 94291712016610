import { useParams } from "react-router";
import AboutUs from "../../components/AboutUs";
import Banner from "../../components/Banner";
import FAQ from "../../components/FAQ";
import NFTPortfolio from "../../components/NFTPortfolio";
import Portfolio from "../../components/Portfolio";
import RoadMap from "../../components/RoadMap";
import TeamMembers from "../../components/TeamMembers";
import Testimonials from "../../components/Testimonials";
import { useEffect } from "react";

const Home = () => {
  const params = useParams<{ id: string }>();

  useEffect(() => {
    setTimeout(() => {
      if (params) {
        const element = document.getElementById(params.id ?? "");

        if (element) {
          const headerOffset = 70; // Adjust this to your header height
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    }, 1000);
  }, [params]);

  return (
    <>
      <div className="wrapper mt-24">
        <Banner />
        <AboutUs />
        <Portfolio />
        <RoadMap />
        <TeamMembers />
        <Testimonials />
        <FAQ />
        <NFTPortfolio />
      </div>
    </>
  );
};

export default Home;

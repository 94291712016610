import { Link } from "react-router";
import { IMAGES } from "../../assets/images";

import { useEffect } from "react";
import { useLocation } from "react-router";
const Tokenomics = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <section className="page-title">
        <div className="shape"></div>
        <div className="shape right s3"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title__body">
                <div className="page-title__main">
                  <h4 className="title">Tokenomics</h4>

                  <ul className="breacrumb">
                    <li>
                      <Link to="/"> Home</Link>
                    </li>
                    <li>
                      <p>Tokenomics</p>
                    </li>
                  </ul>
                  <div className="bottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <p className="py-5">
          The BNL token is designed to ensure a balanced distribution that
          supports the growth and sustainability of the Benylo ecosystem. The
          total supply of BNL tokens is fixed, with allocations distributed to
          various functions to promote development, user engagement, marketing,
          and liquidity.
        </p>
        <h3 className="fs-4">Token Distribution</h3>
        <ul style={{ padding: "2em 2.5em" }}>
          <li style={{ listStyleType: "disc", paddingBottom: "1.5em" }}>
            <p>
              <b className="fs-6">25% Team : </b>A quarter of the total BNL
              supply is allocated to the Benylo team. This allocation is meant
              to incentivize and reward the developers, advisors, and key
              contributors who are instrumental in the success of the project.
              These tokens will be vested over a period of time to ensure
              long-term commitment and alignment with the project's goals.
            </p>
          </li>
          <li style={{ listStyleType: "disc", paddingBottom: "1.5em" }}>
            <p>
              <b className="fs-6">45% Rewards and Airdrop : </b>
              The largest portion of the tokens is reserved for community
              rewards and airdrops. This allocation will be used to incentivize
              user engagement, participation in mini-games, staking rewards, and
              other community-driven activities. A significant initial airdrop
              will be conducted to distribute BNL tokens to early adopters and
              participants, fostering a broad and engaged user base.
            </p>
          </li>
          <li style={{ listStyleType: "disc", paddingBottom: "1.5em" }}>
            <p>
              <b className="fs-6">15% Marketing : </b>
              To ensure the growth and visibility of the Benylo platform, 15% of
              the BNL tokens are allocated for marketing efforts. This includes
              promotional campaigns, partnerships, influencer collaborations,
              and other marketing strategies aimed at increasing brand awareness
              and user adoption.
            </p>
          </li>
          <li style={{ listStyleType: "disc", paddingBottom: "1.5em" }}>
            <p>
              <b className="fs-6">15% Locked Liquidity : </b>
              To provide stability and ensure the liquidity of the BNL token,
              15% of the total supply is locked in liquidity pools. This
              allocation will facilitate seamless trading and conversion of BNL
              tokens on various exchanges, enhancing the token's accessibility
              and market presence.
            </p>
          </li>
        </ul>
        <h3 className="fs-4">Conclusion</h3>
        <p className="py-4">
          The carefully designed tokenomics of the BNL token ensures a balanced
          approach to development, user engagement, marketing, and liquidity. By
          allocating tokens strategically, Benylo aims to build a sustainable
          and thriving ecosystem that benefits all stakeholders.
        </p>
      </div>

      <div className="speciality">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 py-4 px-2">
                  <img
                    src={IMAGES.tokenomics4}
                    style={{ width: "100%" }}
                    alt="tokenomics1"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 py-4 px-2">
                  <img
                    src={IMAGES.tokenomics3}
                    style={{ width: "100%" }}
                    alt="tokenomics2"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 py-4 px-2">
                  <img
                    src={IMAGES.tokenomics2}
                    style={{ width: "100%" }}
                    alt="tokenomics3"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 py-4 px-2">
                  <img
                    src={IMAGES.tokenomics1}
                    style={{ width: "100%" }}
                    alt="tokenomics4"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <img
                src={IMAGES.tokenomicspie}
                style={{ width: "100%" }}
                alt="tokenomicspie"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;

import QRCode from "react-qr-code";
import { useSettingsQuery } from "../hooks/query/useSettings.query";
import { useMemo } from "react";

const NFTPortfolio = () => {
  const { data, isLoading } = useSettingsQuery();

  const settings: TSettings = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);
  return (
    <section className="create">
      <div className="container">
        <div className="create__main">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-xs-12">
              <div className="content">
                <h4 className="heading">Scan to download</h4>
                <p>Driving through the Future of Money! Join Us Today</p>
                <a href={settings?.applicationUrl} className="action-btn">
                  <span>Download</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-flex align-items-center col-xs-12 justify-content-center">
              <div className="qrCode">
                <QRCode size={150} value={settings?.applicationUrl ?? ""} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NFTPortfolio;

import { useEffect, useMemo } from "react";
import { IMAGES } from "../assets/images";
import { useSettingsQuery } from "../hooks/query/useSettings.query";

const Banner = () => {
  const { data, isLoading } = useSettingsQuery();

  const settings: TSettings = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  useEffect(() => {
    // Initialize Swiper when the component mounts
    new Swiper(".banner-slider", {
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      autoplay: {
        delay: 3000,
      },
      spaceBetween: 0,

      loop: true,
    });
  }, []);
  return (
    <section className="banner">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading mb-4">
                  <p className="fs-4 mb-4">Secure, Simple, Seamless:</p>
                  <span className="s1 wow s1-currency" data-splitting>
                    USD
                  </span>
                  <p
                    className="s1-currency"
                    style={{ display: "inline-block" }}
                  >
                    to
                  </p>
                  <span data-splitting className="s1 wow s1-currency">
                    USDT
                  </span>
                  <p
                    className="s1-currency"
                    style={{ display: "inline-block" }}
                  >
                    with
                  </p>
                  <span data-splitting className="s1 wow s1-currency">
                    BNL
                  </span>
                </h2>
                <p className="desc">
                  Experience effortless currency conversion and secure trading,
                  anytime, anywhere with Benylo
                </p>
                <a
                  download={true}
                  target="_blank"
                  href={settings?.applicationUrl}
                  className="action-btn"
                  style={{ marginTop: 40 }}
                >
                  <span>Download Apk</span>
                </a>
              </div>

              {/* <div className="pay">
                <div className="list">
                  <p>We accept:</p>

                  <ul>
                    <li style={{ margin: "5px 10px 0px 0px" }}>
                      <a href="/">
                        <span>
                          <img
                            src={IMAGES.accept1}
                            alt="accept1"
                            style={{ maxWidth: "100%", width: 45 }}
                          />
                        </span>
                      </a>
                    </li>
                    <li style={{ margin: "5px 10px 0px 0px" }}>
                      <a href="/">
                        <span>
                          <img
                            src={IMAGES.accept2}
                            alt="accept2"
                            style={{ maxWidth: "100%", width: 25 }}
                          />
                        </span>
                      </a>
                    </li>
                    <li style={{ margin: "5px 10px 0px 0px" }}>
                      <a href="/">
                        <span>
                          <img
                            src={IMAGES.accept3}
                            alt="accept3"
                            style={{ maxWidth: "100%", width: 35 }}
                          />
                        </span>
                      </a>
                    </li>
                    <li style={{ margin: "5px 10px 0px 0px" }}>
                      <a href="/">
                        <span>
                          <img
                            src={IMAGES.accept4}
                            alt="accept4"
                            style={{ maxWidth: "100%", width: 35 }}
                          />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xl-6 col-md-12 justify-content-center">
            <div className="banner__right">
              <div className="image">
                <div
                  className="swiper banner-slider mobile_banner"
                  style={{
                    zIndex: 12,
                  }}
                >
                  <div className="swiper-wrapper">
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider1} alt="" />
                    </div>

                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider3} alt="" />
                    </div>
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider4} alt="" />
                    </div>
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider5} alt="" />
                    </div>
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider6} alt="" />
                    </div>
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider7} alt="" />
                    </div>
                    <div className="swiper-slide mobile_slider_item">
                      <img src={IMAGES.slider8} alt="" />
                    </div>
                  </div>

                  <div className="mobile_banner_wrapper">
                    <img src={IMAGES.backgroundBg} />
                  </div>
                </div>
                <div>{/* <img src={ } alt="" /> */}</div>
              </div>

              <div className="price">
                <div className="icon">
                  <img src={IMAGES.icon1} style={{ width: 75 }} alt="" />
                </div>
                <div className="content">
                  <h5>BNL</h5>
                </div>
              </div>

              <div className="owner" style={{ zIndex: 15, left: 0 }}>
                <div className="icon">
                  <img src={IMAGES.avt1} alt="" />
                </div>
                <div className="content">
                  <h5>USDT</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import { IMAGES } from "../assets/images";

import benyloWhitepaper from "../assets/benylo-whitepaper.pdf";

const RoadMap = () => {
  return (
    <section className="roadmap" id="WhitePaper">
      <img src={IMAGES.line} alt="" className="img-line" />
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>Road Map</span>
              </h6>
              <h3 className="heading wow" data-splitting>
                Benylo Whitepaper
              </h3>
              <p className="mb-17">
                The original Benylo whitepaper is presented here for historical
                interest only. It should not be relied on for any purpose
                regarding Benylo's operations. In particular and without
                limiting the generality of the foregoing, the original Benylo
                whitepaper has never contained and does not contain legal
                obligations from Benylo.
              </p>
            </div>

            <div
              className="roadmap__main"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="roadmap-box">
                <div className="time">Quarter 2 2024</div>
                <div className="content">
                  <h5 className="title">BNL Token Launch</h5>
                  <p className="text">
                    1. BNL Token Launch : The official launch of the BNL token,
                    marking the beginning of the Benylo ecosystem.
                    <br />
                    <br />
                    2. Development and Launch of USD and USDT Conversion
                    Platform : We will introduce a platform that allows users to
                    seamlessly convert USD to USDT.
                  </p>
                </div>
              </div>
              <div className="roadmap-box right">
                <div className="time">Quarter 3 2024</div>
                <div className="content">
                  <h5 className="title">
                    BNL Token Airdrop, Mini games & Rewards
                  </h5>
                  <p className="text">
                    1. BNL Token Airdrop : To promote community engagement and
                    widespread adoption, we will distribute BNL tokens to early
                    adopters and participants.
                    <br />
                    <br />
                    2. Mini Games Integration and Regular Rewards Distribution :
                    We will integrate mini-games into the platform, allowing
                    users to earn BNL tokens as rewards.
                    <br />
                    <br />
                    3. Leaderboard Rewards: We will launch a leaderboard feature
                    that will enable users to earn exciting rewards based on
                    their liquidity, swap and referral volume.
                  </p>
                </div>
              </div>
              <div className="roadmap-box">
                <div className="time">Quarter 4 2024</div>
                <div className="content">
                  <h5 className="title">BNL Swaps & Enhanced Features</h5>
                  <p className="text">
                    1. Enabling BNL Swap for Other Coins/Tokens : We will enable
                    users to swap BNL for other popular cryptocurrencies such as
                    BTC, ETH, BNB, and SOL.
                    <br />
                    <br />
                    2. USDT to USD Conversion: This feature will allow users to
                    seamlessly convert their USDT to USD directly within the app
                    allowing them to access USD through USDT instead of INR.
                    <br />
                    <br />
                    3. Community Group Chat : Our new community chat feature
                    will allow users to connect in a shared group where they can
                    interact, ask questions, and receive support.
                  </p>
                </div>
              </div>
              <div className="roadmap-box right">
                <div className="time">Quarter 1 2025</div>
                <div className="content">
                  <h5 className="title">Seamless Transactions & P2P Trading</h5>
                  <p className="text">
                    1. Internal and Wallet-to-Wallet Transactions Without Any
                    Fees : We will introduce fee-free transactions within the
                    Benylo platform and between Benylo wallets.
                    <br />
                    <br />
                    2. BNL P2P Buy/Sell Feature Launch : Users will be able to
                    buy and sell BNL tokens directly with each other through a
                    peer-to-peer feature.
                  </p>
                </div>
              </div>
              <div className="roadmap-box">
                <div className="time">Quarter 2 2025</div>
                <div className="content">
                  <h5 className="title">Other Major Exchanges</h5>
                  <p className="text">
                    1. Listing on Major Exchanges : We will work towards getting
                    BNL listed on major cryptocurrency exchanges to enhance its
                    accessibility and liquidity.
                  </p>
                </div>
              </div>
              <div className="icon"></div>
              <div className="icon bottom"></div>
            </div>
            <div className="button">
              <a
                download={"benylo-whitepaper"}
                href={benyloWhitepaper}
                className="action-btn"
                target="_blank"
              >
                <span>View Full Road Map</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;

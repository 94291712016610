import { useQuery } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import { apis } from "../apis";

const get = async () => {
  const response: TServerResponse = await request({
    url: apis.FETCH_REVIEWS,
    method: "GET",
  });
  return response;
};

export const useReviewsQuery = () => {
  return useQuery({
    queryKey: [apis.FETCH_REVIEWS],
    queryFn: get,
  });
};

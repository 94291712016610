import PageTitle from "../../components/about/PageTitle";
import AboutHightQuility from "../../components/about/AboutHightQuility";
import { useLocation } from "react-router";
import { useEffect } from "react";

const About = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <PageTitle />
      <AboutHightQuility />
    </div>
  );
};

export default About;

const FAQ = () => {
  return (
    <section className="faq">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="block-text center"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <h6 className="sub-heading">
                <span>FAQs</span>
              </h6>
              <h3 className="heading">
                Frequently
                <br />
                Aksed Questions
              </h3>
              {/* <p className="mb-17">
                Below is a list of frequently asked questions and answers from
                partners and 3D artist
              </p>
              <p>Please check this FAQ first before contacting us.</p> */}
            </div>

            <div className="faq__main flat-tabs">
              <div className="content-tab">
                <div className="content-inner">
                  <div className="flat-accordion row">
                    <div className="col-md-6 col-sm-12">
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>01.</span> What is Benylo, and how does it work?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            Benylo is a comprehensive platform that enables
                            users to convert USD to USDT seamlessly using BNL
                            tokens, transfer USDT across various wallets or
                            exchanges via TRC20/BEP20, and participate in the
                            crypto economy efficiently.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>02.</span>What is the BNL token ?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            BNL is Benylo's utility token based on BEP 20
                            blockchain, used to bridge USD to USDT within the
                            platform. It is listed on the BSC Chain Network and
                            will soon be featured on CoinMarketCap and
                            CoinGecko.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>03.</span> How is USDT converted to USD?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            When you convert USDT to USD on Benylo, USDT is sold
                            at high live exchange rates, and USD is purchased at
                            current forex rates, maximizing the value of your
                            conversion.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>04.</span> Benefits of completing KYC and blue
                          tick?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            Completing KYC and reward tasks makes you eligible
                            for a verified merchant blue tick, which is
                            displayed on your profile, P2P section, leaderboard,
                            and community chat. Verified merchants can post P2P
                            ads for buying and selling assets conveniently.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>05.</span> Can I withdraw USDT to any wallet?{" "}
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            Yes, once your USDT is swapped within Benylo, it can
                            be withdrawn to any crypto wallet or address or sold
                            on the P2P market. Alternatively, it can be sold to
                            Benylo at high rates without risking any banking
                            related complications.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>06.</span> What is the P2P feature on Benylo?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            Benylo's P2P section allows users to buy or sell
                            assets like BNL, USD, or USDT directly with other
                            users at personalized rates. Verified users can post
                            advertisements for P2P trades, making transactions
                            flexible and convenient.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>07.</span> What is Benylo minigame?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            The minigame offers an interactive way to earn
                            rewards by engaging with the platform. It provides
                            users with an opportunity to win BNL tokens, which
                            can be utilized for various transactions within the
                            Benylo ecosystem.
                          </p>
                        </div>
                      </div>
                      <div className="flat-toggle">
                        <h6 className="toggle-title">
                          <span>08.</span> How can I resolve issues or seek
                          support?
                          <span className="icon-plus"></span>
                        </h6>
                        <div className="toggle-content">
                          <p>
                            For any concerns or issues, such as delays in
                            verification or transaction discrepancies, you can
                            contact the chat support which is available 24 x 7.
                            You can also raise a ticket within the app, and our
                            support team will address your concern promptly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;

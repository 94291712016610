import { useMutation } from "@tanstack/react-query";
import { apis } from "../apis";
import { request } from "../../services/axios.service";
const activeStatus = async (data: { recipient: string }) => {
  const response: TServerResponse = await request({
    url: apis.CONTACT_US,
    method: "POST",
    data: data,
  });
  return response;
};

export const useSubscribeMutation = () => {
  return useMutation({ mutationFn: activeStatus });
};

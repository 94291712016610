import { createBrowserRouter } from "react-router";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import TheLayout from "../container/TheLayout";
import PrivacyPolicy from "../pages/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/termsandconditions/TermsAndConditions";
import Tokenomics from "../pages/tokenomics/Tokenomics";

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/:id",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/termsandconditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/tokenomics",
        element: <Tokenomics />,
      },
    ],
  },
]);

import TheHeader from "./TheHeader";
import { Outlet } from "react-router";
import TheFooter from "./TheFooter";
import PreLoader from "../components/PreLoader";
import ScrollTop from "../components/ScrollTop";

const TheLayout = () => {
  return (
    <div>
      <PreLoader />
      <TheHeader />
      <Outlet />
      <TheFooter />
      <ScrollTop />
    </div>
  );
};

export default TheLayout;

const AboutHightQuility = () => {
  return (
    <section className="about s2" style={{ paddingTop: "106px" }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center pd-0">
              <h6 className="sub-heading">
                <span>About us</span>
              </h6>
            </div>

            <div className="about__main center">
              <p className="mb-17">
                Benylo is an innovative blockchain-powered platform built on the
                BSC Chain Network, designed to redefine the way users manage and
                trade digital assets. At its core, Benylo facilitates seamless
                and secure swaps, including USD to USDT conversions using BNL
                tokens and USDT to USD conversions at high live exchange rates.
                By automatically buying USD at current forex rates, users gain
                more value for their assets, making Benylo a preferred choice
                for financial efficiency.
              </p>
              <p className="mb-17">
                The platform’s robust ecosystem includes the Tap to Earn
                Minigame, which offers users an engaging way to earn rewards
                while exploring the platform. Additionally, Benylo’s liquidity
                pools allow users to contribute to the platform’s stability
                while earning returns on their participation. The P2P market
                empowers users to buy and sell USD, USDT, and BNL tokens
                directly with other users, offering unparalleled flexibility and
                competitive rates. Verified merchants enjoy exclusive privileges
                like posting custom ads and enhanced trading capabilities.
              </p>
              <p className="mb-17">
                Benylo also prioritizes community engagement and user
                convenience. The community chat feature fosters connections
                among users, encouraging collaboration and shared insights. To
                ensure users always have support, Benylo provides 24x7 customer
                service, addressing concerns promptly and professionally.
                Moreover, the platform rewards users for completing tasks like
                KYC, merchant verification, and participating in games, creating
                a rewarding and immersive experience.
              </p>
              <p className="mb-17">
                At the heart of Benylo is the BNL token, a stable and
                utility-driven digital asset that bridges fiat and crypto
                seamlessly. Its stability is backed by robust liquidity
                mechanisms, ensuring trust and long-term reliability. By
                combining advanced blockchain technology with user-focused
                solutions, Benylo aims to provide a comprehensive and rewarding
                ecosystem for digital asset management.
              </p>
              <p className="mb-17">
                Benylo is more than just a platform—it's a community-driven
                solution that empowers users to maximize their digital assets
                with ease, efficiency, and transparency. Join Benylo today to
                explore smarter trading, innovative tools, and a dynamic crypto
                ecosystem tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHightQuility;

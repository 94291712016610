import { memo } from "react";

const PreLoader = () => {
  return (
    <div className="preloader">
      <div className="clear-loading loading-effect-2">
        <span></span>
      </div>
    </div>
  );
};

export default memo(PreLoader);
